import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { firestore } from "../config/firebase";
import { addDoc, collection } from "firebase/firestore";

export const MAX_FILE_SIZE = 512 * 1024 * 1024; // 512MB

export const uploadFile = async (file, fileType, currentUser) => {
  if (!file || !currentUser) return null;

  const storage = getStorage();
  const now = new Date();
  const timestamp =
    now.toISOString().replace(/[-:T]/g, "").split(".")[0] +
    now.getMilliseconds().toString().padStart(3, "0");
  const fileExtension = file.name.split(".").pop();
  const newFileName = `${fileType}_${timestamp}.${fileExtension}`;
  const storageRef = ref(
    storage,
    `${currentUser.uid}/${fileType}/${newFileName}`,
  );

  const metadata = {
    contentType: file.type,
    customMetadata: {
      originalFileName: file.name,
      userId: currentUser.uid,
    },
  };

  try {
    const snapshot = await uploadBytesResumable(storageRef, file, metadata);
    const downloadURL = await getDownloadURL(snapshot.ref);

    const fileDoc = await addDoc(collection(firestore, "files"), {
      userId: currentUser.uid,
      type: fileType,
      path: `${currentUser.uid}/${fileType}/${newFileName}`,
      originalName: file.name,
      createdAt: new Date(),
      status: "uploaded",
    });

    return {
      url: downloadURL,
      path: `${currentUser.uid}/${fileType}/${newFileName}`,
      fileId: fileDoc.id,
    };
  } catch (error) {
    console.error("アップロードに失敗しました", error);
    throw error;
  }
};

export const handleFileChange = (
  file,
  fileType,
  setSourceFile,
  setTargetFile,
  setError,
  MAX_FILE_SIZE,
) => {
  if (!file) {
    if (fileType === "source") {
      setSourceFile(null);
    } else if (fileType === "target") {
      setTargetFile(null);
    }
    return;
  }

  if (file.size > MAX_FILE_SIZE) {
    setError({
      message: "ファイルサイズが大きすぎます",
      details: "最大ファイルサイズは512MBです",
    });
    return;
  }

  const allowedSourceExtensions = ["png", "jpg", "jpeg", "webp"];
  const allowedTargetExtensions = ["png", "jpg", "jpeg", "webp", "webm", "mp4"];
  const fileExtension = file.name.split(".").pop().toLowerCase();

  if (
    fileType === "source" &&
    allowedSourceExtensions.includes(fileExtension)
  ) {
    setSourceFile(file);
    setError({ message: "", details: "" });
  } else if (
    fileType === "target" &&
    allowedTargetExtensions.includes(fileExtension)
  ) {
    // ファイルの読み込みを確認
    const reader = new FileReader();
    reader.onload = (e) => {
      setTargetFile(file);
      setError({ message: "", details: "" });
    };
    reader.onerror = (e) => {
      setError({
        message: "ファイルの読み込みに失敗しました",
        details: "別のファイルを試すか、ブラウザをリロードしてください。",
      });
    };
    reader.readAsArrayBuffer(file);
  } else {
    setError({
      message: "無効なファイル形式です",
      details: `${
        fileType === "source"
          ? "画像ファイル（PNG、JPG、JPEG、WEBP）"
          : "動画または画像ファイル（PNG、JPG、JPEG、WEBP、WEBM、MP4）"
      }を選択してください。`,
    });
  }
};

export const handleDrop = (
  e,
  fileType,
  handleFileChange,
  setSourceFile,
  setTargetFile,
  setError,
  MAX_FILE_SIZE,
) => {
  e.preventDefault();
  const file = e.dataTransfer.files[0];
  handleFileChange(
    file,
    fileType,
    setSourceFile,
    setTargetFile,
    setError,
    MAX_FILE_SIZE,
  );
};

export const handleDragOver = (e) => {
  e.preventDefault();
};

export const handleCancelFile = (
  fileType,
  setSourceFile,
  setTargetFile,
  sourceFileInputRef,
  targetFileInputRef,
) => {
  if (fileType === "source") {
    setSourceFile(null);
    if (sourceFileInputRef.current) {
      sourceFileInputRef.current.value = "";
    }
  } else if (fileType === "target") {
    setTargetFile(null);
    if (targetFileInputRef.current) {
      targetFileInputRef.current.value = "";
    }
  }
};
