import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Eclipse,
  Menu,
  X,
  Home,
  Settings,
  LogOut,
  Sun,
  Moon,
  LogIn,
  UserPlus,
  Info,
} from "lucide-react";
import { ThemeContext } from "../context/ThemeContext";
import { useAuth } from "../context/AuthContext";

const Header = ({ showNotification }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { isDarkMode, toggleDarkMode } = useContext(ThemeContext);
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogout = async () => {
    try {
      await logout();
      showNotification("success", "ログアウトしました");
      navigate("/");
    } catch (error) {
      console.error("Logout failed", error);
      showNotification("error", "ログアウトに失敗しました");
    }
  };

  return (
    <header className="bg-white dark:bg-gray-800 shadow-sm">
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center py-4">
          <Link to="/" className="flex items-center">
            <Eclipse className="h-8 w-8 text-blue-600 dark:text-blue-400 mr-2" />
            <h1 className="text-xl font-semibold text-gray-900 dark:text-white hidden sm:block">
              Magicaly
            </h1>
          </Link>
          <div className="flex items-center">
            {!currentUser && (
              <>
                <Link
                  to="/login"
                  className="mr-4 text-sm font-medium text-gray-700 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400"
                >
                  Login
                </Link>
                <Link
                  to="/register"
                  className="mr-4 text-sm font-medium text-gray-700 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400"
                >
                  Register
                </Link>
              </>
            )}
            <button
              onClick={toggleDarkMode}
              className="mr-4 focus:outline-none"
              aria-label={
                isDarkMode ? "Switch to light mode" : "Switch to dark mode"
              }
            >
              {isDarkMode ? (
                <Sun size={24} className="text-yellow-400" />
              ) : (
                <Moon size={24} className="text-gray-600" />
              )}
            </button>
            <button
              onClick={toggleMenu}
              className="focus:outline-none"
              aria-label={isMenuOpen ? "Close menu" : "Open menu"}
            >
              <Menu size={24} className="text-gray-600 dark:text-gray-300" />
            </button>
          </div>
        </div>
      </div>
      <div
        className={`fixed top-0 right-0 h-full w-64 bg-white dark:bg-gray-800 shadow-lg transform ${
          isMenuOpen ? "translate-x-0" : "translate-x-full"
        } transition-transform duration-300 ease-in-out z-50`}
      >
        <div className="p-4">
          <button
            onClick={toggleMenu}
            className="absolute top-4 right-4 focus:outline-none"
            aria-label="Close menu"
          >
            <X size={24} className="text-gray-600 dark:text-gray-300" />
          </button>
          {currentUser && (
            <div className="mt-8 mb-6">
              <h2 className="text-xl font-bold text-center text-gray-800 dark:text-white">
                {currentUser.displayName || currentUser.email}
              </h2>
            </div>
          )}
          <nav>
            <ul className="space-y-2">
              <li>
                <Link
                  to="/"
                  className="block py-2 px-4 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded transition duration-150"
                  onClick={toggleMenu}
                >
                  <div className="flex items-center">
                    <Home className="mr-3" size={20} />
                    <span>Home</span>
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  to="/about"
                  className="block py-2 px-4 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded transition duration-150"
                  onClick={toggleMenu}
                >
                  <div className="flex items-center">
                    <Info className="mr-3" size={20} />
                    <span>About</span>
                  </div>
                </Link>
              </li>
              {currentUser ? (
                <>
                  <li>
                    <Link
                      to="/settings"
                      className="block py-2 px-4 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded transition duration-150"
                      onClick={toggleMenu}
                    >
                      <div className="flex items-center">
                        <Settings className="mr-3" size={20} />
                        <span>Settings</span>
                      </div>
                    </Link>
                  </li>
                  <li className="pt-4">
                    <button
                      onClick={() => {
                        handleLogout();
                        toggleMenu();
                      }}
                      className="w-full py-2 px-4 text-left text-red-600 dark:text-red-400 hover:bg-red-100 dark:hover:bg-red-900 rounded transition duration-150"
                    >
                      <div className="flex items-center">
                        <LogOut className="mr-3" size={20} />
                        <span>Logout</span>
                      </div>
                    </button>
                  </li>
                </>
              ) : (
                <>
                  <li>
                    <Link
                      to="/login"
                      className="block py-2 px-4 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded transition duration-150"
                      onClick={toggleMenu}
                    >
                      <div className="flex items-center">
                        <LogIn className="mr-3" size={20} />
                        <span>Login</span>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/register"
                      className="block py-2 px-4 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded transition duration-150"
                      onClick={toggleMenu}
                    >
                      <div className="flex items-center">
                        <UserPlus className="mr-3" size={20} />
                        <span>Register</span>
                      </div>
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
