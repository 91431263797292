import React from "react";
import { Link } from "react-router-dom";
import { ExternalLink } from "lucide-react";

const Footer = () => {
  return (
    <footer className="bg-white dark:bg-gray-800 mt-12 border-t border-gray-200 dark:border-gray-700">
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="md:flex md:justify-between">
          <div className="mb-8 md:mb-0">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">
              Magicaly
            </h3>
            <p className="text-gray-600 dark:text-gray-300">
              Transform Your Video & Image✨️ with AI magic.
            </p>
          </div>
          <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-2">
            <div>
              <h4 className="text-sm font-semibold text-gray-900 dark:text-white mb-4">
                Contact
              </h4>
              <ul className="space-y-2">
                <li>
                  <a
                    href="https://discord.gg/xy5WwJz55N"
                    className="text-gray-600 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 transition duration-150 inline-flex items-center"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Discord
                    <ExternalLink className="ml-1 h-4 w-4" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/yasainet"
                    className="text-gray-600 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 transition duration-150 inline-flex items-center"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Twitter
                    <ExternalLink className="ml-1 h-4 w-4" />
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h4 className="text-sm font-semibold text-gray-900 dark:text-white mb-4">
                Legal
              </h4>
              <ul className="space-y-2">
                <li>
                  <Link
                    to="/about"
                    className="text-gray-600 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 transition duration-150"
                  >
                    About
                  </Link>
                </li>
                <li>
                  <Link
                    to="/terms"
                    className="text-gray-600 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 transition duration-150"
                  >
                    Terms of Service
                  </Link>
                </li>
                <li>
                  <Link
                    to="/privacy"
                    className="text-gray-600 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 transition duration-150"
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link
                    to="/legal"
                    className="text-gray-600 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 transition duration-150"
                  >
                    Legal Notice
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="mt-8 pt-8 border-t border-gray-200 dark:border-gray-700 text-center text-gray-500 dark:text-gray-400">
          <p>Made with 🤍 & Magical AI 🌟</p>
          <p className="mt-2">© {new Date().getFullYear()} Magicaly.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
