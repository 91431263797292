import React from "react";

const Announcement = ({ onClose }) => (
  <div className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 mb-4 relative">
    <p className="font-bold">📣 お知らせ</p>
    <ul>
      <li className="text-sm mb-2 leading-relaxed">
        - 変換開始後はページのリロード、移動を行わないでください。
      </li>
    </ul>
    <button
      onClick={onClose}
      className="absolute top-0 right-0 mt-2 mr-2 text-blue-700 hover:text-blue-900"
    >
      ×
    </button>
  </div>
);

export default Announcement;
