import React from "react";
import { Link } from "react-router-dom";

const LoginPrompt = () => {
  return (
    <div className="text-center">
      <p className="mb-4 text-lg text-gray-700 dark:text-gray-300">
        AIによる顔変換を利用するには、ログインまたは新規登録が必要です。
      </p>
      <div className="space-x-4">
        <Link
          to="/login"
          className="inline-block px-6 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          ログイン
        </Link>
        <Link
          to="/register"
          className="inline-block px-6 py-2 text-sm font-medium text-blue-600 bg-white border border-blue-600 rounded-md hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          新規登録
        </Link>
      </div>
    </div>
  );
};

export default LoginPrompt;
