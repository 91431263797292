import React, { useState, useRef } from "react";
import { Upload, Play, User, Film, X } from "lucide-react";
import axios from "axios";
import ErrorMessage from "./ErrorMessage";
import Thumbnail from "./Thumbnail";
import { useAuth } from "../context/AuthContext";
import {
  uploadFile,
  handleFileChange,
  handleDrop,
  handleDragOver,
  handleCancelFile,
  MAX_FILE_SIZE,
} from "./UploadFormHelpers";

const UploadForm = ({
  onProcessingComplete,
  onProcessingStart,
  processingStatus,
}) => {
  const [sourceFile, setSourceFile] = useState(null);
  const [targetFile, setTargetFile] = useState(null);
  const [error, setError] = useState({ message: "", details: "" });
  const sourceFileInputRef = useRef(null);
  const targetFileInputRef = useRef(null);
  const { currentUser } = useAuth();

  const handleProcessStart = async () => {
    if (!sourceFile || !targetFile) {
      setError({
        message: "ファイルが不足しています",
        details: "ソースファイルとターゲットファイルの両方を選択してください。",
      });
      return;
    }

    if (!currentUser) {
      setError({
        message: "ユーザーがログインしていません",
        details: "ファイルを処理するにはログインしてください。",
      });
      return;
    }

    setError({ message: "", details: "" });
    onProcessingStart();

    try {
      // ファイルの読み込みを確認
      const sourceFileReader = new FileReader();
      const targetFileReader = new FileReader();

      const readFile = (file, reader) => {
        return new Promise((resolve, reject) => {
          reader.onload = () => resolve();
          reader.onerror = () =>
            reject(new Error("ファイルの読み込みに失敗しました"));
          reader.readAsArrayBuffer(file);
        });
      };

      await Promise.all([
        readFile(sourceFile, sourceFileReader),
        readFile(targetFile, targetFileReader),
      ]);

      const sourceUpload = await uploadFile(sourceFile, "source", currentUser);
      const targetUpload = await uploadFile(targetFile, "target", currentUser);

      const response = await axios.post(
        `${process.env.REACT_APP_GPU_ENDPOINT}/api/process`,
        {
          userId: currentUser.uid,
          sourceFile: sourceUpload.path,
          sourceFileId: sourceUpload.fileId,
          targetFile: targetUpload.path,
          targetFileId: targetUpload.fileId,
        },
        {
          timeout: 60000,
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      if (response.data && response.data.jobId) {
        onProcessingComplete({ success: true, jobId: response.data.jobId });
      } else {
        throw new Error("No job ID received from server");
      }
    } catch (error) {
      console.error("処理中にエラーが発生しました:", error);
      const errorResult = {
        success: false,
        errorMessage:
          error.response?.data?.error ||
          "予期せぬエラーが発生しました。しばらく待ってから再度お試しください。",
      };
      setError({
        message: "処理に失敗しました",
        details: errorResult.errorMessage,
      });
      onProcessingComplete(errorResult);
    }
  };

  const renderFileInput = (fileType, file, setFile) => (
    <div
      className="bg-gray-200 rounded-lg p-8 text-center cursor-pointer transition duration-300 ease-in-out hover:bg-gray-300 relative"
      onDrop={(e) =>
        handleDrop(
          e,
          fileType,
          handleFileChange,
          setSourceFile,
          setTargetFile,
          setError,
          MAX_FILE_SIZE,
        )
      }
      onDragOver={handleDragOver}
      onClick={() => document.getElementById(`${fileType}File`).click()}
    >
      {file ? (
        <>
          <Thumbnail file={file} />
          <button
            onClick={(e) => {
              e.stopPropagation();
              handleCancelFile(
                fileType,
                setSourceFile,
                setTargetFile,
                sourceFileInputRef,
                targetFileInputRef,
              );
            }}
            className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-1 hover:bg-red-600 transition duration-150"
          >
            <X size={20} />
          </button>
        </>
      ) : (
        <>
          {fileType === "source" ? (
            <User className="mx-auto h-12 w-12 text-gray-500 mb-4" />
          ) : (
            <Film className="mx-auto h-12 w-12 text-gray-500 mb-4" />
          )}
          <h3 className="text-lg font-medium text-gray-900 mb-2">
            {fileType === "source"
              ? "ソース：顔写真"
              : "ターゲット：動画または画像"}
          </h3>
          <p className="text-gray-600 mb-4">
            {fileType === "source" ? (
              <>
                使用したい顔の鮮明な写真をアップロードしてください
                <br />
                対応形式：PNG、JPG、JPEG、WEBP
              </>
            ) : (
              <>
                変換したい動画または画像をアップロードしてください
                <br />
                対応形式：PNG、JPG、JPEG、WEBP、WEBM、MP4
                <br />
                推奨動画形式: フルHD、30fps、512MB以内
              </>
            )}
          </p>
          <button className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition duration-150 inline-flex items-center">
            <Upload className="mr-2" size={20} />
            {fileType === "source"
              ? "顔写真をアップロード"
              : "動画/画像をアップロード"}
          </button>
        </>
      )}
      <input
        type="file"
        id={`${fileType}File`}
        ref={fileType === "source" ? sourceFileInputRef : targetFileInputRef}
        accept={
          fileType === "source"
            ? ".png,.jpg,.jpeg,.webp"
            : ".png,.jpg,.jpeg,.webp,.webm,.mp4"
        }
        onChange={(e) =>
          handleFileChange(
            e.target.files[0],
            fileType,
            setSourceFile,
            setTargetFile,
            setError,
            MAX_FILE_SIZE,
          )
        }
        className="hidden"
      />
    </div>
  );

  return (
    <div className="space-y-6">
      {renderFileInput("source", sourceFile, setSourceFile)}
      {renderFileInput("target", targetFile, setTargetFile)}
      <button
        onClick={handleProcessStart}
        disabled={
          !sourceFile || !targetFile || processingStatus || !currentUser
        }
        className="w-full bg-blue-600 text-white font-medium py-3 px-4 rounded-lg hover:bg-blue-700 transition duration-150 flex items-center justify-center disabled:opacity-50"
      >
        <Play className="mr-2" size={20} />
        {processingStatus ? "処理中..." : "処理を開始"}
      </button>
      <ErrorMessage message={error.message} details={error.details} />
    </div>
  );
};

export default UploadForm;
