import React from "react";
import { AlertCircle } from "lucide-react";

const ErrorMessage = ({ message, details }) => {
  if (!message) return null;

  return (
    <div
      className="mt-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
      role="alert"
    >
      <div className="flex items-center">
        <AlertCircle className="h-5 w-5 mr-2" aria-hidden="true" />
        <strong className="font-bold">{message}</strong>
      </div>
      {details && <span className="block sm:inline mt-1">{details}</span>}
    </div>
  );
};

export default ErrorMessage;
