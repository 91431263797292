import React, { createContext, useState, useEffect, useContext } from "react";
import { auth, firestore } from "../config/firebase";
import { onAuthStateChanged, signOut, updateProfile } from "firebase/auth";
import { doc, getDoc, updateDoc } from "firebase/firestore";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userDoc = await getDoc(doc(firestore, "users", user.uid));
        if (userDoc.exists()) {
          setCurrentUser({ ...user, ...userDoc.data() });
        } else {
          setCurrentUser(user);
        }
      } else {
        setCurrentUser(null);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const logout = () => {
    return signOut(auth);
  };

  const updateUserProfile = async (profile) => {
    await updateProfile(auth.currentUser, profile);
    await updateUserData(profile);
  };

  const updateUserData = async (data) => {
    if (currentUser) {
      const userRef = doc(firestore, "users", currentUser.uid);
      await updateDoc(userRef, data);
      setCurrentUser((prev) => ({ ...prev, ...data }));
    }
  };

  const value = {
    currentUser,
    loading,
    logout,
    updateUserProfile,
    updateUserData,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
