import React from "react";
import { Loader2 } from "lucide-react";

const ProcessingIndicator = ({ status }) => {
  const steps = [
    { key: "uploading", label: "ファイルアップロード中" },
    { key: "processing", label: "変換処理中" },
  ];

  const currentStepIndex = steps.findIndex((step) => step.key === status) || 0;

  return (
    <div className="flex flex-col items-center justify-center mt-8">
      <div className="flex items-center space-x-2 mb-4">
        {steps.map((step, index) => (
          <React.Fragment key={step.key}>
            <div
              className={`w-8 h-8 rounded-full flex items-center justify-center ${
                index <= currentStepIndex
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 text-gray-500"
              }`}
            >
              {index < currentStepIndex ? (
                "✓"
              ) : index === currentStepIndex ? (
                <Loader2 className="animate-spin h-5 w-5" />
              ) : (
                index + 1
              )}
            </div>
            {index < steps.length - 1 && (
              <div
                className={`w-16 h-1 ${
                  index < currentStepIndex ? "bg-blue-500" : "bg-gray-200"
                }`}
              />
            )}
          </React.Fragment>
        ))}
      </div>
      <p className="mt-4 text-lg font-semibold text-gray-700 dark:text-gray-300">
        {steps[currentStepIndex].label}...👩‍💻💫
      </p>
      <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
        変換結果が表示されるまでブラウザを開いたままお待ちください
      </p>
    </div>
  );
};

export default ProcessingIndicator;
