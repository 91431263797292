import React, { useState, useEffect } from "react";

const Thumbnail = ({ file }) => {
  const [thumbnail, setThumbnail] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (file) {
      setLoading(true);
      setError(null);

      const generateThumbnail = async () => {
        try {
          if (file.type.startsWith("image/")) {
            const reader = new FileReader();
            reader.onload = (e) => {
              setThumbnail(e.target.result);
              setLoading(false);
            };
            reader.onerror = () => {
              throw new Error("Failed to read image file");
            };
            reader.readAsDataURL(file);
          } else if (file.type.startsWith("video/")) {
            const video = document.createElement("video");
            video.preload = "metadata";
            video.playsInline = true;
            video.muted = true;
            video.crossOrigin = "anonymous";

            video.onloadeddata = () => {
              video.currentTime = 1;
            };

            video.onseeked = () => {
              const canvas = document.createElement("canvas");
              canvas.width = video.videoWidth;
              canvas.height = video.videoHeight;
              canvas
                .getContext("2d")
                .drawImage(video, 0, 0, canvas.width, canvas.height);
              setThumbnail(canvas.toDataURL());
              setLoading(false);
              URL.revokeObjectURL(video.src);
            };

            video.onerror = () => {
              throw new Error("Failed to load video file");
            };

            video.src = URL.createObjectURL(file);
            video.load();
          } else {
            throw new Error("Unsupported file type");
          }
        } catch (err) {
          console.error("Error generating thumbnail:", err);
          setError(err.message);
          setLoading(false);
        }
      };

      generateThumbnail();

      return () => {
        // Clean up any resources if component unmounts
        if (file.type.startsWith("video/")) {
          URL.revokeObjectURL(file);
        }
      };
    }
  }, [file]);

  if (loading) {
    return <p>Loading thumbnail...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div className="w-full h-48 flex items-center justify-center">
      {thumbnail && (
        <img
          src={thumbnail}
          alt="Thumbnail"
          className="max-w-full max-h-full object-contain"
        />
      )}
    </div>
  );
};

export default Thumbnail;
