import React from "react";
import { Helmet } from "react-helmet";

const TermsOfService = () => {
  return (
    <>
      <Helmet>
        <title>利用規約 | Magicaly</title>
        <meta
          name="description"
          content="Magicalyのサービス利用に関する規約と条件をご覧いただけます。"
        />
      </Helmet>
      <div className="max-w-4xl mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-6 text-gray-900 dark:text-white">
          利用規約
        </h1>
        <section className="mb-8">
          <p className="text-gray-600 dark:text-gray-300 mb-4">WIP...</p>
        </section>
      </div>
    </>
  );
};

export default TermsOfService;
