import React, { useState, useEffect } from "react";
import { Download } from "lucide-react";
import { useAuth } from "../context/AuthContext";
import { firestore } from "../config/firebase";
import { doc, getDoc } from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { getApp } from "firebase/app";

const ResultView = ({
  jobId,
  processingStatus,
  onProcessingFinish,
  onClose,
}) => {
  const [videoError, setVideoError] = useState(false);
  const [fileName, setFileName] = useState("");
  const [fileExtension, setFileExtension] = useState("");
  const [isSafari, setIsSafari] = useState(false);
  const [jobData, setJobData] = useState(null);
  const [error, setError] = useState(null);
  const [showMessage, setShowMessage] = useState(false);
  const { currentUser } = useAuth();

  useEffect(() => {
    let isMounted = true;
    let retryCount = 0;
    const maxRetries = 5;

    const fetchJobData = async () => {
      if (!currentUser || !jobId) return;

      try {
        const jobDoc = await getDoc(doc(firestore, "jobs", jobId));
        if (!isMounted) return;

        if (jobDoc.exists()) {
          const jobDataFromFirestore = jobDoc.data();
          setJobData(jobDataFromFirestore);

          if (jobDataFromFirestore.status === "processing") {
            // 処理中の場合、5秒後に再度確認
            setTimeout(() => fetchJobData(), 5000);
            return;
          }

          if (
            jobDataFromFirestore.status === "completed" &&
            jobDataFromFirestore.outputFilePath
          ) {
            const app = getApp();
            const storage = getStorage(app);
            const fileRef = ref(storage, jobDataFromFirestore.outputFilePath);
            const url = await getDownloadURL(fileRef);
            const updatedJobData = {
              ...jobDataFromFirestore,
              outputFileUrl: url,
            };
            setJobData(updatedJobData);

            // ファイル名と拡張子を設定
            const filenameParts = jobDataFromFirestore.outputFilePath
              .split("/")
              .pop()
              .split(".");
            setFileName(filenameParts[0]);
            setFileExtension(filenameParts[1]);

            // onProcessingFinish が提供されている場合のみ呼び出す
            if (onProcessingFinish) {
              onProcessingFinish(updatedJobData);
            }
          }
        } else {
          if (retryCount < maxRetries) {
            retryCount++;
            // ジョブデータが見つからない場合、1秒後に再試行
            setTimeout(() => fetchJobData(), 1000);
          } else {
            setError("Job not found or access denied");
          }
        }
      } catch (err) {
        console.error("Error fetching job data:", err);
        setError("Failed to load job data");
      }
    };

    fetchJobData();
    setIsSafari(/^((?!chrome|android).)*safari/i.test(navigator.userAgent));

    return () => {
      isMounted = false;
    };
  }, [currentUser, jobId, onProcessingFinish]);

  useEffect(() => {
    const isIPhoneOrAndroid = /iPhone|iPad|iPod|Android/i.test(
      navigator.userAgent,
    );
    const isVideoResult = ["mp4", "webm"].includes(fileExtension);
    setShowMessage(isIPhoneOrAndroid && isVideoResult);
  }, [fileExtension]);

  if (processingStatus) {
    return null; // 処理中は何も表示しない
  }

  if (error) {
    return (
      <div className="text-red-500 dark:text-red-400 mt-4" role="alert">
        {error}
      </div>
    );
  }

  if (!jobData) {
    return <div className="text-center">No result available</div>;
  }

  const isVideo = ["mp4", "webm"].includes(fileExtension);
  const isImage = ["png", "jpg", "webp"].includes(fileExtension);

  const handleVideoError = () => {
    setVideoError(true);
  };

  const handleDownload = async (e) => {
    e.preventDefault();
    const fileUrl = jobData.outputFileUrl;
    if (!fileUrl) {
      console.error("Output file URL not available");
      return;
    }

    try {
      const response = await fetch(fileUrl, {
        method: "GET",
        credentials: "omit",
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${fileName}.${fileExtension}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("ダウンロードに失敗しました:", error);
      alert("ダウンロードに失敗しました。後でもう一度お試しください。");
    }
  };

  return (
    <div
      className="bg-white dark:bg-gray-800 shadow-lg rounded-lg p-8"
      aria-label="処理結果"
    >
      <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-6">
        処理結果
      </h2>
      <div className="mb-6">
        {jobData.status === "completed" && jobData.outputFileUrl && (
          <>
            {isVideo ? (
              <>
                {isSafari ? (
                  <div className="text-gray-600 dark:text-gray-300 mb-4">
                    変換処理が完了しました。ダウンロードしてご確認ください。
                  </div>
                ) : videoError ? (
                  <div className="text-red-500">
                    動画の読み込みに失敗しました。リンクが期限切れの可能性があります。
                  </div>
                ) : (
                  <video
                    controls
                    className="w-full rounded-lg shadow-md"
                    onError={handleVideoError}
                  >
                    <source
                      src={jobData.outputFileUrl}
                      type={`video/${fileExtension}`}
                    />
                    お使いのブラウザは動画タグをサポートしていません。
                  </video>
                )}
                {showMessage && (
                  <div className="mt-4 p-4 bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700">
                    <p className="font-bold">
                      ⚠️
                      再生ができない時は、LINEなどに送信を行うことで再生がされます。
                    </p>
                    <p className="mt-2">
                      <strong>iPhone の場合:</strong>
                    </p>
                    <ol className="list-decimal list-inside ml-4">
                      <li>
                        ダウンロードしたファイルを開き、「共有ボタン」を押してください
                      </li>
                      <li>
                        「LINE」を選択して「Keepメモ」など、ご自身のみが確認できる送信先に共有してください
                      </li>
                      <li>
                        以上の工程を行うことで、保存と再生がより確実に行われます。
                      </li>
                    </ol>
                    <p className="mt-2">👩‍💻 アップデートして改善する予定です</p>
                  </div>
                )}
              </>
            ) : isImage ? (
              <img
                src={jobData.outputFileUrl}
                alt="処理結果"
                className="w-full rounded-lg shadow-md"
                loading="lazy"
              />
            ) : (
              <div className="text-gray-500">
                サポートされていないファイル形式: {fileExtension}
              </div>
            )}
            <button
              onClick={handleDownload}
              className="w-full mt-4 bg-blue-600 hover:bg-blue-700 dark:bg-blue-500 dark:hover:bg-blue-600 text-white font-medium py-3 px-4 rounded-lg transition duration-150 flex items-center justify-center"
              aria-label="結果をダウンロード"
            >
              <Download className="mr-2" size={20} />
              結果をダウンロード
            </button>
          </>
        )}
        {jobData.status === "failed" && (
          <div className="text-red-600 dark:text-red-400">
            処理に失敗しました: {jobData.error}
          </div>
        )}
      </div>
      <button
        onClick={onClose}
        className="w-full bg-gray-300 hover:bg-gray-400 dark:bg-gray-700 dark:hover:bg-gray-600 text-gray-800 dark:text-white font-medium py-2 px-4 rounded-lg transition duration-150"
      >
        閉じる
      </button>
    </div>
  );
};

export default ResultView;
