import React from "react";
import { Helmet } from "react-helmet";

const LegalNotice = () => {
  return (
    <>
      <Helmet>
        <title>特定商取引法に基づく表記 | Magicaly</title>
        <meta
          name="description"
          content="Magicalyの会社情報、連絡先情報、免責事項など、法的情報をご覧いただけます。"
        />
      </Helmet>
      <div className="max-w-4xl mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-6 text-gray-900 dark:text-white">
          特定商取引法に基づく表記
        </h1>
        <section className="mb-8">
          <p className="text-gray-600 dark:text-gray-300 mb-4">WIP...</p>
        </section>
      </div>
    </>
  );
};

export default LegalNotice;
