import React from "react";
import { Helmet } from "react-helmet";

const About = () => {
  return (
    <>
      <Helmet>
        <title>About Magicaly | AI-Powered Face Swapping</title>
        <meta
          name="description"
          content="Magicalyは、AIを活用して動画や画像の顔を変換するWebサービスです。使い方と写真・動画のコツをご紹介します。"
        />
      </Helmet>
      <div className="max-w-4xl mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-6 text-gray-900 dark:text-white">
          Magicaly ってなに？
        </h1>

        <section className="mb-8">
          <p className="text-gray-600 dark:text-gray-300 mb-4">
            Magicaly は、AI を活用して動画や画像の顔を変換する Webサービスです。
          </p>
          <p className="text-gray-600 dark:text-gray-300 mb-4">
            <strong>Made with 🤍 Magical AI 🌟</strong>
          </p>
          <p className="text-gray-600 dark:text-gray-300 mb-4">
            顔写真（ソース）と変換したい動画または画像（ターゲット）をアップロードして変換をすると、AIによる顔変換処理を行うサービスです。
            AIによる顔変換にはちょっとしたコツが必要があります。
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-gray-800 dark:text-gray-200">
            使い方
          </h2>
          <ol className="list-decimal list-inside text-gray-600 dark:text-gray-300">
            <li>「新規登録」をして、アカウントを作成してください。</li>
            <li>ソース: 顔写真をアップロードしてください。</li>
            <li>
              ターゲット: 変換したい動画または画像をアップロードしてください。
            </li>
            <li>
              変換ボタンを押してください。変換結果が返ってくるまで少しだけ時間がかかります。
              <ol className="list-lower-alpha pl-4">
                <li>1分の動画で、およそ2分〜3分程度かかります。</li>
                <li>ページを移動せずにそのまま開いて待機してください。</li>
              </ol>
            </li>
            <li>
              変換結果が返ってきたら、ダウンロードをしてください。
              <ol className="list-lower-alpha pl-4">
                <li>
                  ⚠️ iPhone, Android
                  の一部環境において再生がされないことがありますが、以下の方法で再生ができます。
                </li>
                <li>
                  例えば、LINEの
                  Keepメモなどに共有をしてください。再生できることが確認できます。
                </li>
              </ol>
            </li>
          </ol>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-gray-800 dark:text-gray-200">
            写真と動画のコツ
          </h2>
          <p className="text-gray-600 dark:text-gray-300 mb-4">
            使い方を覚えたけど、変換結果に満足できないあなたへ！
            AIの技術は万能ではありません。しかしながら、このコツを掴んだらきっとあなたが想像している以上のクオリティで変換処理が可能です。
            ぜひ、いろいろと試してみてください！
          </p>

          <h3 className="text-xl font-semibold mb-2 text-gray-800 dark:text-gray-200">
            知ってて欲しいこと
          </h3>
          <ul className="list-disc list-inside text-gray-600 dark:text-gray-300 mb-4">
            <li>
              マスクをつけた画像など、顔がわかりづらいファイルには対応できないです。
            </li>
            <li>ソースとターゲットの前髪・髪型が似ていると精度🆙です。</li>
            <li>
              複雑な顔の表情や、45度以上横や上を向いているターゲットは顔認識機能がうまく機能しないことがあります。
            </li>
            <li>
              ターゲットの顔がアップになりすぎると、少しだけ画質が下がってしまうことがあります。適切な画角があるので、試行錯誤してみてください！
            </li>
          </ul>

          <h3 className="text-xl font-semibold mb-2 text-gray-800 dark:text-gray-200">
            顔画像（ソース画像）
          </h3>
          <ul className="list-disc list-inside text-gray-600 dark:text-gray-300 mb-4">
            <li>
              正面からの写真で、しっかりと顔が写っているのが望ましいです。そう、証明写真みたいな感じが一番最高です。
            </li>
            <li>
              前述したとおり、前髪のあるなしはとても大事です。ターゲットの前髪に似ている写真が望ましいので覚えててください。
            </li>
          </ul>

          <h4 className="text-lg font-semibold mb-2 text-gray-800 dark:text-gray-200">
            ソース画像サンプル.1
          </h4>
          <img
            src="/images/sample-source-1.png"
            alt="sample source 1"
            className="mb-4"
          />

          <h4 className="text-lg font-semibold mb-2 text-gray-800 dark:text-gray-200">
            ソース画像サンプル.2
          </h4>
          <img
            src="/images/sample-source-2.png"
            alt="sample source 2"
            className="mb-4"
          />

          <h3 className="text-xl font-semibold mb-2 text-gray-800 dark:text-gray-200">
            変換動画・画像（ターゲット動画）
          </h3>
          <ul className="list-disc list-inside text-gray-600 dark:text-gray-300 mb-4">
            <li>
              ターゲットも同じく、正面から顔がハッキリと確認できるものが望ましいです。
            </li>
          </ul>

          <h4 className="text-lg font-semibold mb-2 text-gray-800 dark:text-gray-200">
            ターゲットサンプル.1
          </h4>
          <img
            src="/images/sample-target-1.png"
            alt="sample target 1"
            className="mb-4"
          />

          <h4 className="text-lg font-semibold mb-2 text-gray-800 dark:text-gray-200">
            ターゲットサンプル.2
          </h4>
          <img
            src="/images/sample-target-2.png"
            alt="sample target 2"
            className="mb-4"
          />
        </section>
      </div>
    </>
  );
};

export default About;
