import React, { useState, useCallback, useRef, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "./context/ThemeContext";
import { AuthProvider, useAuth } from "./context/AuthContext";
import Header from "./components/Header";
import Footer from "./components/Footer";
import UploadForm from "./components/UploadForm";
import ResultView from "./components/ResultView";
import ProcessingIndicator from "./components/ProcessingIndicator";
import ErrorMessage from "./components/ErrorMessage";
import Notification from "./components/Notification";
import Announcement from "./components/Announcement";
import LoginPrompt from "./components/LoginPrompt";
import Register from "./pages/Register";
import Login from "./pages/Login";
import Settings from "./pages/Settings";
import ForgotPassword from "./pages/ForgotPassword";
import About from "./pages/About";
import TermsOfService from "./pages/TermsOfService";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import LegalNotice from "./pages/LegalNotice";

function AppContent() {
  const { currentUser } = useAuth();
  const [result, setResult] = useState(null);
  const [processingStatus, setProcessingStatus] = useState(null);
  const [globalError, setGlobalError] = useState(null);
  const [notification, setNotification] = useState(null);
  const [showAnnouncement, setShowAnnouncement] = useState(true);

  const showNotification = useCallback((type, message) => {
    setNotification({ type, message });
  }, []);

  const handleProcessingStart = useCallback(() => {
    setProcessingStatus("uploading");
    setGlobalError(null);
    setResult(null);
    showNotification("info", "ファイルをアップロード中です");
  }, [showNotification]);

  const handleProcessingComplete = useCallback(
    (resultData) => {
      if (resultData.success) {
        setResult(resultData);
        setProcessingStatus("processing");
        showNotification("info", "変換処理を開始しました");
      } else {
        setGlobalError({
          message: "処理の開始に失敗しました",
          details: resultData.errorMessage,
        });
        setProcessingStatus(null);
        showNotification("error", "処理の開始に失敗しました");
      }
    },
    [showNotification],
  );

  const handleProcessingFinish = useCallback(
    (finishedResult) => {
      setResult(finishedResult);
      setProcessingStatus(null);
      showNotification("success", "変換処理が完了しました");
    },
    [showNotification],
  );

  const resultViewRef = useRef(null);

  useEffect(() => {
    if (result) {
      resultViewRef.current?.focus();
    }
  }, [result]);

  const handleCloseAnnouncement = () => {
    setShowAnnouncement(false);
  };

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
      <Header showNotification={showNotification} />
      <main className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {showAnnouncement && <Announcement onClose={handleCloseAnnouncement} />}
        <Routes>
          <Route
            path="/"
            element={
              <>
                {globalError && (
                  <ErrorMessage
                    message={globalError.message}
                    details={globalError.details}
                  />
                )}
                <div className="bg-white dark:bg-gray-800 shadow-lg rounded-lg p-8 mb-8">
                  <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-6">
                    AI で動画の顔を変換✨️
                  </h2>
                  {currentUser ? (
                    <>
                      <UploadForm
                        onProcessingComplete={handleProcessingComplete}
                        onProcessingStart={handleProcessingStart}
                        processingStatus={processingStatus}
                      />
                      {processingStatus && (
                        <ProcessingIndicator status={processingStatus} />
                      )}
                    </>
                  ) : (
                    <LoginPrompt />
                  )}
                </div>
                {result && (
                  <ResultView
                    jobId={result.jobId}
                    processingStatus={processingStatus}
                    onProcessingFinish={handleProcessingFinish}
                    onClose={() => {
                      setResult(null);
                      setProcessingStatus(null);
                    }}
                  />
                )}
              </>
            }
          />
          <Route
            path="/register"
            element={<Register showNotification={showNotification} />}
          />
          <Route
            path="/login"
            element={<Login showNotification={showNotification} />}
          />
          <Route path="/settings" element={<Settings />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/about" element={<About />} />
          <Route path="/terms" element={<TermsOfService />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/legal" element={<LegalNotice />} />
        </Routes>
      </main>
      <Footer />
      {notification && (
        <Notification
          type={notification.type}
          message={notification.message}
          onClose={() => setNotification(null)}
        />
      )}
    </div>
  );
}

function App() {
  return (
    <Router>
      <ThemeProvider>
        <AuthProvider>
          <AppContent />
        </AuthProvider>
      </ThemeProvider>
    </Router>
  );
}

export default App;
